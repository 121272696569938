import React from "react"
import { Accordion } from "react-headless-accordion"
import BaseAcordeonItem from "./base-acordeon-item"

const BaseAccordionGroup = ({ data }) => {
  return (
    <>
      {data.map((accordion) => (
        <Accordion key={accordion.id}>
          <BaseAcordeonItem
            key={accordion.id}
            isActive={accordion.isOpen}
            label={accordion.title}
            classNameLabel="text-brand-primary md:text-7xl"
            body={accordion?.nested?.map((child) => (
              <div id={child.idAccordion} key={child.id}>
                <BaseAcordeonItem
                  withBorder={false}
                  label={child.title}
                  isActive={child.isOpen}
                  body={
                    <div className="pb-4 text-xl font-medium">
                      {child.body?.data?.childMarkdownRemark && (
                        <div
                          className="prose"
                          dangerouslySetInnerHTML={{
                            __html: child.body?.data?.childMarkdownRemark?.html,
                          }}
                        />
                      )}
                    </div>
                  }
                />
              </div>
            ))}
            lgPadding={true}
            cols={1}
          />
        </Accordion>
      ))}
    </>
  )
}

export default BaseAccordionGroup
