import PropTypes from "prop-types"
import React from "react"
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion"
import { classNames } from "../utilities"
import BaseProse from "./base-prose"
import { PipeIcon, PlusIcon } from "./icons"

const BaseAcordeonItem = ({
  label,
  body,
  className,
  classNameLabel,
  lgPadding,
  withBorder,
  cols,
  children,
  ...rest
}) => {
  return (
    <AccordionItem {...rest}>
      {({ open }) => (
        <div
          className={classNames(
            "border border-x-0 border-t-0 border-b-black",
            className
          )}
        >
          <AccordionHeader
            as="div"
            className={classNames(
              "flex w-full cursor-pointer items-center justify-between",
              lgPadding ? "pb-24 pt-8" : "pb-7 pt-5",
              withBorder
                ? "border-2 border-x-0 border-t-0 border-b-black"
                : "border-x-0 border-y-0"
            )}
          >
            <div
              className={classNames(
                "text-4xl font-semibold md:text-5xl",
                classNameLabel
              )}
            >
              <div className={`lg:grid-cols-${cols} grid pb-5 md:grid-cols-1`}>
                <div className="col-span-1 text-left">{label}</div>
              </div>
            </div>
            <div className="flex w-5 items-center justify-center">
              {open ? <PipeIcon /> : <PlusIcon />}
            </div>
          </AccordionHeader>
          <AccordionBody>
            <BaseProse className="prose-brand-primary">
              <div className={`lg:grid-cols-${cols} grid md:grid-cols-1`}>
                {body || children}
              </div>
            </BaseProse>
          </AccordionBody>
        </div>
      )}
    </AccordionItem>
  )
}

BaseAcordeonItem.defaultProps = {
  withBorder: true,
  cols: 2,
}

BaseAcordeonItem.propTypes = {
  body: PropTypes.any,
  children: PropTypes.any,
  classNameLabel: PropTypes.string,
  className: PropTypes.string,
  cols: PropTypes.number,
  label: PropTypes.string,
  lgPadding: PropTypes.bool,
  withBorder: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default BaseAcordeonItem
