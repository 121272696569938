import React from "react"
import PropTypes from "prop-types"

import { classNames } from "../utilities"

const BaseProse = ({ children, className }) => {
  return (
    <div
      className={classNames("prose max-w-none lg:max-w-screen-xl ", className)}
    >
      {children}
    </div>
  )
}

BaseProse.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default BaseProse
