import React from "react"
import PropTypes from "prop-types"
import { classNames } from "../utilities"

const IconLink = ({ icon, linkTitle, url }) => {
  return (
    <div className="my-4 flex flex-col items-center justify-center lg:items-end lg:justify-start">
      <div className="flex flex-col items-center justify-center gap-y-6">
        <img
          src={icon?.url}
          alt={icon?.alternativeText || ""}
          className="h-auto w-[50px]"
          loading="lazy"
        />
        <a
          href={url}
          className="text-xl font-semibold transition-colors duration-300 hover:text-brand-primary"
        >
          {linkTitle}
        </a>
      </div>
    </div>
  )
}

const BaseHeader = ({
  children,
  className,
  title,
  titleLg,
  image,
  cols,
  linkTitle,
  url,
  icon,
}) => {
  return (
    <header
      className={classNames(
        "mx-auto my-4 max-w-screen-xl px-4 lg:my-14 lg:px-10",
        className
      )}
    >
      {(title || image) && (
        <div className="w-full py-4">
          {title && (
            <div
              className={classNames(
                "grid grid-cols-1 pb-10 md:grid-cols-1",
                cols === 2 || icon ? "lg:grid-cols-2" : "lg:grid-cols-3"
              )}
            >
              <h1
                className={classNames(
                  "w-full text-5xl font-semibold lg:text-8xl",
                  titleLg ? "col-span-2" : "col-span-1"
                )}
              >
                {title}
              </h1>

              {icon && <IconLink icon={icon} linkTitle={linkTitle} url={url} />}
            </div>
          )}
          {image && <div className="mt-8 grid grid-cols-1">{image}</div>}
        </div>
      )}
      {children}
    </header>
  )
}

BaseHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  cols: PropTypes.number,
  image: PropTypes.any,
  title: PropTypes.string,
  titleLg: PropTypes.bool,
  url: PropTypes.string,
}

export default BaseHeader
